@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter Tight', sans-serif;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.landing-page {
  background: linear-gradient(to top, #000000, rgba(0, 0, 0, 0.8) 60%, #000000);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 0 20px; /* Padding for smaller screens */
}

.landing-page h1 {
    font-size: 56px; /* Default for larger screens */
    font-weight: 500;
    margin-bottom: 16px;
    line-height: 1.1;
    width: 70%; /* Default width */
  }
  
  /* For tablets and below (screen width 768px and below) */
  @media screen and (max-width: 768px) {
    .landing-page h1 {
      font-size: 40px; /* Reduce font size for smaller screens */
      width: 100%; /* Increase width to accommodate smaller screens */
    }
  }
  
  /* For mobile phones (screen width 480px and below) */
  @media screen and (max-width: 480px) {
    .landing-page h1 {
      font-size: 32px; /* Further reduce font size for mobile */
      width: 100%; /* Full width for very small screens */
    }
  }

.landing-page p {
  font-size: 20px; /* Matches the body text size */
  font-weight: 400;
  margin-bottom: 32px; /* Matches the spacing between text and input group */
  line-height: 1.5; /* Slightly looser for better readability */
  max-width: 600px; /* Keeps text contained on wider screens */
  color: gray;
}

@media screen and (max-width: 768px) {
    .landing-page p {
      font-size: 18px; /* Reduce font size for smaller screens */
      width: 90%; /* Increase width to accommodate smaller screens */
    }
  }

.input-group {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.input-group input {
  padding: 16px;
  border-radius: 4px 0 0 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: 'Inter Tight', sans-serif;
  flex-grow: 1;
  max-width: 300px;
}

.input-group input::placeholder {
  color: rgba(255, 255, 255, 0.6); /* Subtle placeholder text */
}

.input-group button {
  padding: 16px 24px;
  border-radius: 0 4px 4px 0;
  background-color: #3B82F6;
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Inter Tight', sans-serif;
  cursor: pointer;
}

.input-group button:hover {
  background-color: #2563EB;
}

.landing-page::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 220px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 1) 70%);
  z-index: -1; /* Places the gradient behind other elements */
}
